import React from "react";
import styles from "./styles.module.scss";
import { linkConfig } from "config";
import queryString from 'query-string';

class DownloadDetailJn extends React.Component {
  state = {
    platform: "jn",
    title: "必拓环球 (BT Global)"
  };
  componentDidMount() {
    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      if (
        params.platform &&
        params.title &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          platform: params.platform,
          title: params.title
        });
      }
    }
  }
  render() {
    console.log(linkConfig.download.ios)
    // const iosUrl = `itms-services://?action=download-manifest&url=${
    //   linkConfig.download.ios[this.state.platform]
    // }`;
    return (
      <React.Fragment>
        <div className={`${styles.local_bg} ${this.state.platform === "jn" && styles.local_jnBg}`}>
          <h2>全球著名的差价合约交易平台</h2>
          <p>
            <a href={linkConfig.download.ios.new_jn[0]}>立即安装地址一</a>
            <a href={linkConfig.download.ios.new_jn[1]}>立即安装地址二</a>
          </p>
          <p className="info">提示安装“BT Global” 时，请点击安装按钮</p>
          <p className="info">如果下载不了APP，请先删除原有APP</p>
          <div className="local_bg_body">
            <h3>安装完成后，请按以下操作</h3>
            <p>
              打开<b>「设置」→</b>
              <b>「通用」→</b>
              <b>「设备管理器」</b>
              找到 (金牛财富) App的描述文件，设置为信任。
            </p>
            <img
              alt={this.state.title}
              src={`/download/detail_trtorial_jn.png`}
            />
            <p>设置完成后，就可以使用“金牛财富”了</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DownloadDetailJn;
