import React from "react";
import styles from "./styles.module.scss";
import { linkConfig } from "config";
import queryString from 'query-string';

class Detail extends React.Component {
  state = {
    platform: "btcc",
    title: "必拓环球 (BT Global)"
  };
  componentDidMount() {
    if (window.location.search) {
      const params = queryString.parse(window.location.search);
      if (
        params.platform &&
        params.title &&
        Object.keys(linkConfig.download.ios).includes(params.platform)
      ) {
        this.setState({
          platform: params.platform,
          title: params.title
        });
      }
    }
  }
  render() {
    const iosUrl = `itms-services://?action=download-manifest&url=${
      linkConfig.download.ios[this.state.platform]
    }`;
    return (
      <React.Fragment>
        <div className={styles.local_bg}>
          <h2>全球著名的差价合约交易平台</h2>
          <p>
            {
              this.state.platform === "btcc" ?
              (
                // 條件符合btcc時下載連結為兩個
                <React.Fragment>
                  <a href={linkConfig.download.ios.new_btcc[0]}>立即安装地址一</a>
                  <a href={linkConfig.download.ios.new_btcc[1]}>立即安装地址二</a>
                </React.Fragment>
              )
              :
              (
                <React.Fragment>
                  <a href={iosUrl}>立即安装</a>
                </React.Fragment>
              )
            }
          </p>
          <p className="info">提示安装“BT Global” 时，请点击安装按钮</p>
          <p className="info">如果下载不了APP，请先删除原有APP</p>
          <div className="local_bg_body">
            <h3>安装完成后，请按以下操作</h3>
            <p>
              打开<b>「设置」→</b>
              <b>「通用」→</b>
              <b>「设备管理器」</b>
              找到 (必拓环球) App的描述文件，设置为信任。
            </p>
            <img
              alt={this.state.title}
              src={`/download/detail_trtorial.png`}
            />
            <p>设置完成后，就可以使用“必拓环球”了</p>
          </div>
        </div>

        {/* <div className={styles.step_detail}>
          <p className="flex-container">
            <span>1. 打开</span>
            <span className={styles.icon_seting} />
            <span>“系统设置”，找到“通用”。</span>
          </p>
          <img alt={this.state.title} src="/download/t_1.png" />
          <p>2. 点击“通用”里的“设备管理”。</p>
          <img alt={this.state.title} src="/download/t_2.png" />
          <p>3. 点击“设备管理”中红色标注的企业级应用。</p>
          <img
            alt={this.state.title}
            src={`http://btjiaoge.com/app/iosazjc/btccgl/t_1.jpg`}
          />
          <p>4. 点击红色标注的“信任”按钮</p>
          <img
            alt={this.state.title}
            src={`http://btjiaoge.com/app/iosazjc/btccgl/t_2.jpg`}
          />
          <p>设置完成后，就可以使用“BT Global App”了</p>
          <p>5. 在弹窗中，点击“信任”。</p>
          <img
            alt={this.state.title}
            src={`http://btjiaoge.com/app/iosazjc/${this.state.platform}/t_5.png`}
          />
          <p>
            6. 看到这个页面，恭喜，安装完成了,可以正常使用{this.state.title}
            App了。
          </p>
          <img
            alt={this.state.title}
            src={`http://btjiaoge.com/app/iosazjc/${this.state.platform}/t_6.png`}
          />

          <p className={styles.fixed_bottom}>
            <a href="/btccgl.mobileprovision" className={styles.confirm}>
              立即信任
            </a>
          </p>
        </div> */}
      </React.Fragment>
    );
  }
}
export default Detail;
