import './polyfill'
import React from "react";
import ReactDOM from "react-dom";
import "rest-css";
import "antd-mobile/dist/antd-mobile.css";
import "./scss/index.scss";
import Router from "./router";
import queryString from 'query-string';
const { userType = '', isReaded = '', customerNo = '', token = '' } = queryString.parse(window.location.search);

let link = 'https://120.79.254.1:6006';
if (process.env.BUILD_ENV === "uat") {
    link = 'https://www.gtsuat.com';
}
// 兼容app 积分任务新手宝典跳转
if (window.location.pathname === '/novice_book') {
    const url = userType === 'old_user' ? `${link}/active/old-novices-h5/old_user.html?userType=old_user` : `${link}/active/old-novices-h5/new_user.html?userType=new_user`;
    window.location.replace(`${url}&customerNo=${customerNo}&token=${token}&isReaded=${isReaded}`);
}

ReactDOM.render(<Router />, document.getElementById("root"));

